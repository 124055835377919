import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://phpstack-1125153-3940518.cloudwaysapps.com/public";

const token = localStorage.getItem("token");
const role_id = localStorage.getItem("role_id");
var roleName = "";
if (role_id == "1") {
  roleName = "admin";
} else if (role_id == "2") {
  roleName = "pm";
}else if (role_id == "3") {
  roleName = "dh";
}else if (role_id == "4") {
  roleName = "tm";
}else if (role_id == "6") {
  roleName = "monitor";
}

export const getStatics = createAsyncThunk(
  "statics/fetshstatics",
  async ({date1, date2}, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const res = await axios.get(
        `${endpoint}/api/${roleName}/g-reports?filter=1&date1=${date1}&date2=${date2}`,
        {
          headers,
        }
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const staticsSlice = createSlice({
  name: "statics",
  initialState: {
    isLoading: true,
    statics: {},
  },
  reducers: {},
  extraReducers: {
    [getStatics.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getStatics.fulfilled]: (state, action) => {
      // console.log(action);
      state.isLoading = false;
      state.statics = action.payload;
    },
    [getStatics.rejected]: (state, action) => {
      state.isLoading = false;
    },
  }
});

export default staticsSlice.reducer;
